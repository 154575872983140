import { makeApi, Zodios } from '@zodios/core';
import { z } from 'zod';

type GroupItem = {
  id: UUID;
  owner: Owner;
  name: string;
  members: Array<UUID>;
  children: Array<GroupItem>;
};
type UUID = string;
type Owner = {
  id: UUID;
  type: OwnerType;
};
type OwnerType = 'organization';

const Versions = z.object({
  app_version: z.string(),
  open_api_version: z.string(),
});
const DeviceVersionChannel = z.enum(['release', 'development', 'debug']);
const DeviceVersion = z.object({
  version: z.string(),
  channel: DeviceVersionChannel,
});
const NextCursor = z.string();
const PaginatedDeviceVersions = z.object({
  versions: z.array(DeviceVersion),
  next_cursor: NextCursor.nullable(),
});
const UUID = z.string();
const OwnerType = z.enum(['organization']);
const Owner = z.object({ id: UUID.min(36).max(36).uuid(), type: OwnerType });
const MetadataWithoutName = z.object({
  id: UUID.min(36).max(36).uuid(),
  last_updated: z.string(),
  owner: Owner,
});
const ResourceReferenceStatus = z.enum(['valid', 'outdated', 'invalid']);
const ResourceReference = z.object({
  id: UUID.min(36).max(36).uuid(),
  name: z.string(),
  checksum: z.string(),
  status: ResourceReferenceStatus,
});
const RunHistoryOutcome = z.enum([
  'in_progress',
  'failed',
  'finished',
  'canceled',
]);
const Tag = z.object({
  id: UUID.min(36).max(36).uuid(),
  name: z.string(),
  color: z.string(),
  is_archived: z.boolean(),
});
const Tags = z.object({ tags: z.array(Tag) });
const RunHistory = MetadataWithoutName.and(
  z.object({
    device_id: UUID.min(36).max(36).uuid(),
    workflow: ResourceReference.optional(),
    start_time: z.string(),
    stop_time: z.string().optional(),
    outcome: RunHistoryOutcome,
    outcome_details: z.string().optional(),
  })
).and(Tags);
const ErrorSeverityEnum = z.enum(['normal', 'warning', 'critical']);
const RequiredString = z.string();
const DeviceErrorInstrument = z.object({
  id: RequiredString.min(1),
  name: RequiredString.min(1),
  type: RequiredString.min(1),
});
const DeviceErrorAction = z.enum(['reset', 'skip-task', 'retry-task']);
const BasicUserInfo = z.object({
  id: UUID.min(36).max(36).uuid(),
  name: z.string(),
  email: z.string().email(),
});
const DeviceError = z.object({
  id: UUID.min(36).max(36).uuid(),
  timestamp: z.string(),
  error_message: z.string(),
  error_code: z.string(),
  error_severity: ErrorSeverityEnum,
  run_history_id: UUID.min(36).max(36).uuid(),
  instrument: DeviceErrorInstrument.optional(),
  action_taken: DeviceErrorAction.optional(),
  actioned_at: z.string().optional(),
  actioned_by: BasicUserInfo.optional(),
  available_actions: z.array(DeviceErrorAction),
});
const PauseWarningAffectedTimeConstraint = z.object({
  constraint_id: z.string(),
  response_time: z.string(),
});
const PauseWarningAvailableAction = z.enum(['cancel', 'commit']);
const PauseWarningAvailableActions = z.array(PauseWarningAvailableAction);
const PauseWarning = z.object({
  constraints: z.array(PauseWarningAffectedTimeConstraint),
  available_actions: PauseWarningAvailableActions,
});
const TimeConstraintBrokenPrompt = z.object({
  id: UUID.min(36).max(36).uuid(),
  constraints: z.array(z.string()),
  can_continue: z.boolean(),
});
const OperatorAction = z.object({ message: z.string() });
const DeviceStateStatus = z.enum([
  'no_workflow',
  'init',
  'loading',
  'uncalibrated',
  'ready',
  'running',
  'paused',
  'stopping',
  'resetting',
  'error',
  'action_required',
  'emergency_stop',
  'aborted',
]);
const DeviceStateAlertReactionBase = z.object({ id: z.string() });
const DeviceStateAlertReactionMood = z.enum([
  'non_specific',
  'acknowledge',
  'confirm',
  'cancel',
]);
const DeviceStateAlertReactionButton = DeviceStateAlertReactionBase.and(
  z.object({
    reaction_type: z.enum(['button']),
    type: z.string(),
    mood: DeviceStateAlertReactionMood,
    label: z.string(),
  })
);
const DeviceStateAlertReactionText = DeviceStateAlertReactionBase.and(
  z.object({
    reaction_type: z.enum(['text_form']),
    type: z.string(),
    mood: DeviceStateAlertReactionMood,
    button_label: z.string(),
    input_label: z.string(),
    placeholder: z.string(),
    confirm_button_label: z.string(),
    disable_file_upload: z.boolean(),
  })
);
const DeviceStateAlertReaction = z.union([
  DeviceStateAlertReactionButton,
  DeviceStateAlertReactionText,
]);
const DeviceStateAlert = z.object({
  id: z.string(),
  type: z.string(),
  title: z.string(),
  message: z.string(),
  fully_blocking: z.boolean(),
  triggered: z.string(),
  reactions: z.array(DeviceStateAlertReaction),
});
const DeviceState = z.object({
  status: DeviceStateStatus,
  details: z.string(),
  run_history_id: UUID.min(36).max(36).uuid().optional(),
  iteration: z.number().int().optional(),
  alerts: z.array(DeviceStateAlert).optional(),
});
const DeviceDataState = DeviceState.and(
  z.object({ id: UUID.min(36).max(36).uuid(), timestamp: z.string() })
);
const DeviceDataStates = z.array(DeviceDataState);
const PaginatedDeviceDataStates = z.object({
  list: DeviceDataStates,
  next_cursor: NextCursor.nullable(),
});
const BatchIDs = z.array(UUID);
const DeviceDataLog = z.object({
  id: UUID.min(36).max(36).uuid(),
  timestamp: z.string(),
  message: z.string(),
  run_history_id: UUID.min(36).max(36).uuid().optional(),
});
const DeviceDataLogs = z.array(DeviceDataLog);
const PaginatedDeviceDataLogs = z.object({
  list: DeviceDataLogs,
  next_cursor: NextCursor.nullable(),
});
const DeviceDataOnlyContent = z.object({
  states: PaginatedDeviceDataStates,
  batch_ids: BatchIDs,
  logs: PaginatedDeviceDataLogs,
});
const RunParameterType = z.enum(['number', 'string', 'boolean', 'file']);
const RunParameterDefinitionBase = z.object({
  id: UUID.min(36).max(36).uuid(),
  label: z.string(),
  name: z.string(),
});
const RunParameterDefinitionNumber = RunParameterDefinitionBase.and(
  z.object({
    type: z.enum(['number']),
    default_value: z.number(),
    min_value: z.number().optional(),
    max_value: z.number().optional(),
  })
);
const RunParameterValueNumber = z.object({
  id: UUID.min(36).max(36).uuid(),
  definition: RunParameterDefinitionNumber,
  type: z.enum(['number']),
  value: z.number(),
});
const RunParameterDefinitionString = RunParameterDefinitionBase.and(
  z.object({ type: z.enum(['string']), default_value: z.string() })
);
const RunParameterValueString = z.object({
  id: UUID.min(36).max(36).uuid(),
  definition: RunParameterDefinitionString,
  type: z.enum(['string']),
  value: z.string(),
});
const RunParameterDefinitionBoolean = RunParameterDefinitionBase.and(
  z.object({ type: z.enum(['boolean']), default_value: z.boolean() })
);
const RunParameterValueBoolean = z.object({
  id: UUID.min(36).max(36).uuid(),
  definition: RunParameterDefinitionBoolean,
  type: z.enum(['boolean']),
  value: z.boolean(),
});
const RunParameterDefinitionFile = RunParameterDefinitionBase.and(
  z.object({ type: z.enum(['file']) })
);
const FileReference = z.object({ filename: z.string() });
const RunParameterValueFile = z.object({
  id: UUID.min(36).max(36).uuid(),
  definition: RunParameterDefinitionFile,
  type: z.enum(['file']),
  value: FileReference,
});
const RunParameterValue = z
  .object({ type: RunParameterType })
  .and(
    z.union([
      RunParameterValueNumber,
      RunParameterValueString,
      RunParameterValueBoolean,
      RunParameterValueFile,
    ])
  );
const RunParameterValues = z.array(RunParameterValue);
const ReferenceDataType = z.enum(['string', 'file', 'sample_map']);
const ReferenceDataDefinitionBase = z.object({
  id: UUID.min(36).max(36).uuid(),
  name: z.string(),
  label: z.string(),
});
const ReferenceDataDefinitionString = ReferenceDataDefinitionBase.and(
  z.object({ type: z.enum(['string']), default_value: z.string() })
);
const ReferenceDataValueString = z.object({
  id: UUID.min(36).max(36).uuid(),
  type: z.enum(['string']),
  definition: ReferenceDataDefinitionString,
  value: z.string(),
});
const ReferenceDataDefinitionFile = ReferenceDataDefinitionBase.and(
  z.object({ type: z.enum(['file']) })
);
const ReferenceDataValueFile = z.object({
  id: UUID.min(36).max(36).uuid(),
  type: z.enum(['file']),
  definition: ReferenceDataDefinitionFile,
  value: FileReference,
});
const ReferenceDataDefinitionSampleMap = ReferenceDataDefinitionBase.and(
  z.object({ type: z.enum(['sample_map']) })
);
const ReferenceDataValueSampleMap = z.object({
  id: UUID.min(36).max(36).uuid(),
  type: z.enum(['sample_map']),
  definition: ReferenceDataDefinitionSampleMap,
  value: FileReference,
});
const ReferenceDataValue = z
  .object({ type: ReferenceDataType })
  .and(
    z.union([
      ReferenceDataValueString,
      ReferenceDataValueFile,
      ReferenceDataValueSampleMap,
    ])
  );
const ReferenceDataValues = z.array(ReferenceDataValue);
const DeviceDataOnly = z
  .object({
    error: DeviceError,
    pause_warning: PauseWarning,
    time_constraint_broken_prompt: TimeConstraintBrokenPrompt,
    operator_action: OperatorAction,
    data: DeviceDataOnlyContent,
    run_parameters: RunParameterValues,
    reference_data_values: ReferenceDataValues,
  })
  .partial();
const RunHistoryDetailed = RunHistory.and(DeviceDataOnly).and(Tags);
const ExportAuditLogs = z.object({ download_url: z.string() });
const MaestroTaskInstrument = z.object({
  id: z.string(),
  name: z.string(),
  type: z.string(),
  driver: z.string(),
});
const MaestroBaseTask = z.object({
  id: z.string(),
  instrument: MaestroTaskInstrument,
  action: z.string(),
});
const MaestroTaskLabware = z.object({
  id: z.string(),
  type: z.string(),
  batch: z.number().int(),
});
const MaestroTransportTask = MaestroBaseTask.and(
  z.object({
    labware: MaestroTaskLabware,
    source_task_id: z.string(),
    destination_task_id: z.string(),
  })
);
const MaestroActionTask = MaestroBaseTask.and(
  z.object({
    is_manual_interaction: z.boolean(),
    labware_in: z.array(MaestroTaskLabware),
    labware_out: z.array(MaestroTaskLabware),
  })
);
const MaestroTask = z.union([MaestroTransportTask, MaestroActionTask]);
const MaestroTaskProgressDuration = z.object({
  start: z.number().int(),
  end: z.number().int(),
});
const MaestroTaskProgressError = z.object({
  code: z.string(),
  message: z.string(),
});
const MaestroTaskProgress = z.object({
  task: MaestroTask,
  status: z.enum(['pending', 'in_progress', 'succeeded', 'failed']),
  duration: MaestroTaskProgressDuration,
  error: MaestroTaskProgressError.optional(),
});
const BatchStatus = z.enum([
  'not_started',
  'in_progress',
  'completed',
  'failed',
  'aborted',
]);
const BatchTrace = z.object({
  id: UUID.min(36).max(36).uuid(),
  run_history_id: UUID.min(36).max(36).uuid(),
  start_time: z.string().optional(),
  end_time: z.string().optional(),
  batch_number: z.number().int(),
  status: BatchStatus,
});
const RunPauseAttempt = z.object({
  id: UUID.min(36).max(36).uuid(),
  run_id: UUID.min(36).max(36).uuid(),
  requested_at: z.string(),
  requested_by: BasicUserInfo,
  is_maestro_run: z.boolean(),
  state: z.enum([
    'requested',
    'warning_issued',
    'cancelled',
    'confirmed',
    'pausing',
    'active',
    'resuming',
    'resumed',
    'run_stopped',
  ]),
  reason: z.string(),
  pause_warning_event_id: UUID.min(36).max(36).uuid().optional(),
  active_at: z.string().optional(),
  resumed_at: z.string().optional(),
  resumed_by: BasicUserInfo.optional(),
});
const RunPauseInfo = z.object({
  pause_attempts: z.array(RunPauseAttempt),
  total_time_paused_secs: z.number(),
  is_currently_paused: z.boolean(),
  current_pause_start_time: z.string().optional(),
});
const MaestroRunProgress = z.object({
  maestro_run_start_time: z.string().optional(),
  task_progress: z.array(MaestroTaskProgress),
  batch_progress: z.array(BatchTrace),
  pauses: RunPauseInfo,
  total_estimated_time: z.number().int(),
});
const HubEventLog = z.object({
  id: UUID.min(36).max(36).uuid(),
  hub_serial: RequiredString.min(1),
  run_id: UUID.min(36).max(36).uuid(),
  response_to: UUID.min(36).max(36).uuid().optional(),
  timestamp: z.string(),
  event_type: RequiredString.min(1),
  topic: RequiredString.min(1),
  priority: RequiredString.min(1),
  details: z.string(),
  instrument_id: z.string().optional(),
  task_id: z.string().optional(),
});
const HubEventLogs = z.array(HubEventLog);
const Metadata = MetadataWithoutName.and(z.object({ name: z.string() }));
const MetadataWithChecksum = Metadata.and(z.object({ checksum: z.string() }));
const WorkflowType = z.enum(['python', 'git', 'maestro']);
const WorkflowGitRevisionKind = z.enum(['commit']);
const WorkflowGitRevision = z.object({
  kind: WorkflowGitRevisionKind,
  ref: RequiredString.min(1),
});
const ResourceVersions = z.record(z.string());
const WorkflowGit = z.object({
  ssh_uri: RequiredString.min(1),
  ssh_private_key_pem: RequiredString.min(1),
  folder: RequiredString.min(1),
  workflow_file: z.string().optional(),
  revision: WorkflowGitRevision,
  resource_versions: ResourceVersions,
});
const WorkflowMaestro = z.object({
  workflow: z.string().min(1),
  simulateDrivers: z.boolean(),
  simulateTime: z.boolean(),
  transport: z.string().min(1),
  resource_versions: ResourceVersions,
});
const WorkflowDefinition = z
  .object({ python: z.string(), git: WorkflowGit, maestro: WorkflowMaestro })
  .partial();
const RunInstructionType = z.enum(['pre', 'mid', 'post']);
const RunInstruction = z.object({
  description: z.string(),
  type: RunInstructionType,
  id: UUID.min(36).max(36).uuid(),
});
const RunInstructions = z.object({
  pre: z.array(RunInstruction),
  mid: z.array(RunInstruction),
  post: z.array(RunInstruction),
});
const RunParameterDefinition = z
  .object({ type: RunParameterType })
  .and(
    z.union([
      RunParameterDefinitionNumber,
      RunParameterDefinitionString,
      RunParameterDefinitionBoolean,
      RunParameterDefinitionFile,
    ])
  );
const RunParameterDefinitions = z.array(RunParameterDefinition);
const ReferenceDataDefinition = z
  .object({ type: ReferenceDataType })
  .and(
    z.union([
      ReferenceDataDefinitionString,
      ReferenceDataDefinitionFile,
      ReferenceDataDefinitionSampleMap,
    ])
  );
const ReferenceDataDefinitions = z.array(ReferenceDataDefinition);
const WorkflowAttributes = z
  .object({
    parameters: RunParameterDefinitions,
    reference_data_definitions: ReferenceDataDefinitions,
  })
  .partial();
const WorkflowDetailed = MetadataWithChecksum.and(
  z.object({
    type: WorkflowType,
    definition: WorkflowDefinition,
    run_instructions: RunInstructions.optional(),
    attributes: WorkflowAttributes.optional(),
  })
).and(Tags);
const ReasonDetails = z.object({ details: RequiredString.min(1) });
const Reason = z.object({ reason: ReasonDetails });
const RunInstructionPayload = z.object({
  id: UUID.min(36).max(36).uuid().optional(),
  description: z.string(),
  type: RunInstructionType,
});
const RunInstructionsPayload = z.object({
  pre: z.array(RunInstructionPayload),
  mid: z.array(RunInstructionPayload),
  post: z.array(RunInstructionPayload),
});
const WorkflowPayload = z.object({
  name: RequiredString.min(1),
  definition: WorkflowDefinition,
  run_instructions: RunInstructionsPayload.optional(),
  attributes: WorkflowAttributes.optional(),
});
const updateWorkflow_Body = Reason.and(z.object({ payload: WorkflowPayload }));
const DuplicateResourcePayload = z.object({ name: RequiredString.min(1) });
const Workflow = MetadataWithChecksum.and(z.object({ type: WorkflowType })).and(
  Tags
);
const MaestroVersion = z.object({
  name: z.string(),
  published_at: z.string(),
  compatible: z.boolean(),
  drivers_version: z.string(),
});
const MaestroVersions = z.object({ versions: z.array(MaestroVersion) });
const EvalVersion = z.object({ name: z.string(), published_at: z.string() });
const EvalVersions = z.object({
  org_slug: z.string(),
  versions: z.array(EvalVersion),
});
const ArchetypeVersion = z.object({ versions: z.array(z.string()) });
const ArchetypeVersions = z.object({ packages: z.record(ArchetypeVersion) });
const deviceIDOrSerial = z.union([UUID, RequiredString]);
const DeviceDeviceState = DeviceState.and(
  z.object({ last_updated: z.string(), uuid: z.string() })
);
const DeviceWorkflow = z.object({
  id: UUID.min(36).max(36).uuid(),
  name: z.string(),
  checksum: z.string(),
  last_updated: z.string(),
  uuid: z.string(),
});
const Device = z.object({
  id: UUID.min(36).max(36).uuid(),
  owner: Owner,
  name: z.string(),
  serial: z.string(),
  online: z.boolean(),
  state: DeviceDeviceState,
  version: z.string(),
  last_access: z.string(),
  workflow: DeviceWorkflow.optional(),
});
const DeviceData = Device.and(DeviceDataOnly);
const PolyDetails = z.union([
  z.union([z.string(), z.array(z.unknown()), z.object({}).partial()]),
  z.array(z.union([z.string(), z.array(z.unknown()), z.object({}).partial()])),
]);
const DeviceDataMetricBase = z.object({
  id: UUID.min(36).max(36).uuid(),
  range_start: z.string(),
  range_end: z.string().optional(),
  name: z.string(),
  details: PolyDetails.optional(),
  run_history_id: UUID.min(36).max(36).uuid().optional(),
});
const DeviceDataMetricInteger = DeviceDataMetricBase.and(
  z.object({ type: z.enum(['integer']), value: z.number().int() })
);
const DeviceDataMetricFloat = DeviceDataMetricBase.and(
  z.object({ type: z.enum(['float']), value: z.number() })
);
const DeviceDataMetricString = DeviceDataMetricBase.and(
  z.object({ type: z.enum(['string']), value: z.string() })
);
const DeviceDataMetricBoolean = DeviceDataMetricBase.and(
  z.object({ type: z.enum(['boolean']), value: z.boolean() })
);
const DeviceDataMetric = z.union([
  DeviceDataMetricInteger,
  DeviceDataMetricFloat,
  DeviceDataMetricString,
  DeviceDataMetricBoolean,
]);
const DeviceDataMetrics = z.array(DeviceDataMetric);
const PaginatedDeviceDataMetrics = z.object({
  list: DeviceDataMetrics,
  next_cursor: NextCursor.nullable(),
});
const DeviceDataRunHistories = z.array(RunHistory);
const PaginatedDeviceRunHistories = z.object({
  list: DeviceDataRunHistories,
  next_cursor: NextCursor.nullable(),
});
const RenamePayload = z.object({ name: RequiredString.min(1) });
const RunParameterValueNumberPayload = z.object({
  definition_id: UUID.min(36).max(36).uuid(),
  type: z.enum(['number']),
  value: z.number(),
});
const RunParameterValueStringPayload = z.object({
  definition_id: UUID.min(36).max(36).uuid(),
  type: z.enum(['string']),
  value: z.string(),
});
const RunParameterValueBooleanPayload = z.object({
  definition_id: UUID.min(36).max(36).uuid(),
  type: z.enum(['boolean']),
  value: z.boolean(),
});
const RunParameterValueFilePayload = z.object({
  definition_id: UUID.min(36).max(36).uuid(),
  type: z.enum(['file']),
  value: FileReference,
});
const RunParameterValuePayload = z
  .object({ type: RunParameterType })
  .and(
    z.union([
      RunParameterValueNumberPayload,
      RunParameterValueStringPayload,
      RunParameterValueBooleanPayload,
      RunParameterValueFilePayload,
    ])
  );
const RunParameterValuePayloads = z.array(RunParameterValuePayload);
const ReferenceDataValueStringPayload = z.object({
  type: z.enum(['string']),
  definition_id: UUID.min(36).max(36).uuid(),
  value: z.string(),
});
const ReferenceDataValueFilePayload = z.object({
  type: z.enum(['file']),
  definition_id: UUID.min(36).max(36).uuid(),
  value: FileReference,
});
const ReferenceDataValueSampleMapPayload = z.object({
  type: z.enum(['sample_map']),
  definition_id: UUID.min(36).max(36).uuid(),
  value: FileReference,
});
const ReferenceDataValuePayload = z
  .object({ type: ReferenceDataType })
  .and(
    z.union([
      ReferenceDataValueStringPayload,
      ReferenceDataValueFilePayload,
      ReferenceDataValueSampleMapPayload,
    ])
  );
const ReferenceDataValuePayloads = z.array(ReferenceDataValuePayload);
const LoadPayload = z.object({
  workflow_id: UUID.min(36).max(36).uuid(),
  run_parameters: RunParameterValuePayloads.optional(),
  reference_data_values: ReferenceDataValuePayloads.optional(),
  tag_ids: z.array(UUID).optional(),
});
const loadWorkflowOnDevice_Body = Reason.and(
  z.object({ payload: LoadPayload, file: z.instanceof(File).optional() })
);
const deployWorkflowToDevice_Body = Reason.and(
  z.object({
    payload: LoadPayload,
    parameter_files: z.array(z.instanceof(File)).optional(),
    reference_data_files: z.array(z.instanceof(File)).optional(),
  })
);
const DeviceConfiguration = z.object({
  hardware: z.string(),
  timezone: z.string(),
});
const ConfigurePayload = z
  .object({ hardware: z.string(), timezone: z.string() })
  .partial();
const configureDevice_Body = Reason.and(
  z.object({ payload: ConfigurePayload })
);
const StartParameters = z.object({ times_to_run: z.number().int() }).partial();
const startDevice_Body = Reason.and(z.object({ payload: StartParameters }));
const StopReasonEnum = z.enum([
  'scheduled_end',
  'hardware_protection',
  'missing_input',
  'operator_error',
]);
const StopReasonDetails = z.object({
  code: StopReasonEnum,
  details: z.string(),
});
const StopReason = z.object({ reason: StopReasonDetails });
const stopDevice_Body = Reason.and(z.object({ payload: StopReason }));
const AlertResponseNone = z.object({ type: z.enum(['none']) });
const AlertResponseText = z.object({
  type: z.enum(['text']),
  text: z.string(),
});
const AlertResponse = z.object({
  alert_id: z.string(),
  reaction_id: z.string(),
  serial: z.string(),
  response: z.union([AlertResponseNone, AlertResponseText]),
});
const DeviceErrorActionPayload = z.object({ action: DeviceErrorAction });
const NullableUUID = z.string();
const Group = z.object({
  parent_id: NullableUUID.min(36).max(36).uuid().nullish(),
  name: RequiredString.min(1),
  members: z.array(UUID),
});
const FlatGroup = z.object({
  id: UUID.min(36).max(36).uuid(),
  parent_id: NullableUUID.min(36).max(36).uuid().nullish(),
  name: z.string(),
  members: z.array(UUID),
  owner: Owner,
});
const MovePayload = z
  .object({ group_id: NullableUUID.min(36).max(36).uuid().nullable() })
  .partial();
const MoveResult = z.object({ to: FlatGroup, from: FlatGroup }).partial();
const Workflows = z.object({
  workflows: z.array(Workflow),
  next_cursor: NextCursor.nullable(),
});
const WorkflowCreatePayload = z.object({
  name: RequiredString.min(1),
  type: WorkflowType,
});
const Devices = z.object({
  devices: z.array(Device),
  next_cursor: NextCursor.nullable(),
});
const FlatGroups = z.array(FlatGroup);
const PaginatedFlatGroups = z.object({
  groups: FlatGroups,
  next_cursor: NextCursor.nullable(),
});
const ComplianceResourceConfig = z.object({
  create: z.boolean(),
  rename: z.boolean(),
  duplicate: z.boolean(),
  delete: z.boolean(),
  edit: z.boolean(),
});
const ComplianceDeviceStateConfig = z.object({
  start: z.boolean(),
  pause: z.boolean(),
  resume: z.boolean(),
  stop: z.boolean(),
  reset: z.boolean(),
  load: z.boolean(),
  configure: z.boolean(),
});
const ComplianceConfig = z.object({
  workflow: ComplianceResourceConfig,
  device: ComplianceResourceConfig,
  device_state: ComplianceDeviceStateConfig,
  group: ComplianceResourceConfig,
});
const OrganizationConfig = z.object({
  password_expiry: z.number().int(),
  compliance: ComplianceConfig,
});
const Organization = z.object({
  id: UUID.min(36).max(36).uuid(),
  logo: z.string().url(),
  name: z.string(),
  workspace: UUID.min(36).max(36).uuid(),
  slug: z.string(),
  externalID: z.string(),
  configuration: OrganizationConfig,
});
const OrganizationsV2 = z.object({ organizations: z.array(Organization) });
const UserStatus = z.enum(['active', 'invited', 'disabled']);
const User = z.object({
  user_id: UUID.min(36).max(36).uuid(),
  email: z.string().email(),
  picture: z.string().url(),
  name: z.string(),
  employee_id: z.string().optional(),
  status: UserStatus,
  added_at: z.string(),
});
const Scope = z.enum(['workflow', 'device', 'device_state', 'group']);
const Action = z.enum([
  'create',
  'rename',
  'duplicate',
  'delete',
  'edit',
  'start',
  'pause',
  'resume',
  'stop',
  'reset',
  'load',
  'configure',
]);
const RequestIntentionRequest = z.object({ scope: Scope, action: Action });
const RequestIntentionResponse = z.object({ requestIntention: z.string() });
const RequestVerifyRequest = z.object({
  requestIntention: RequiredString.min(1),
});
const RequestVerifyResponse = z.object({ token: z.string() });
const DeviceDataSubscription = z.object({ id: UUID.min(36).max(36).uuid() });
const DeviceDataSubMetric = z.object({
  id: UUID.min(36).max(36).uuid(),
  metrics: DeviceDataMetrics,
});
const DeviceDataSubLog = z.object({
  id: UUID.min(36).max(36).uuid(),
  logs: DeviceDataLogs,
});
const dummyInternalDoNotUse_Body = z.union([
  DeviceDataSubscription,
  DeviceDataSubMetric,
  DeviceDataSubLog,
]);
const NotificationChannel = z.object({
  slug: z.string(),
  enabled: z.boolean(),
});
const NotificationCategory = z.object({
  slug: z.string(),
  channels: z.array(NotificationChannel),
});
const NotificationResource = z.object({ devices: z.array(UUID) });
const UserNotificationPreferences = z.object({
  categories: z.array(NotificationCategory),
  resources: NotificationResource,
});
const DeviceNotificationPreferences = z.object({
  device_id: UUID.min(36).max(36).uuid(),
  enabled: z.boolean(),
});
const OrganizationPayload = z.object({
  logo: z.string().url().optional(),
  name: z.string(),
  slug: z.string(),
  configuration: OrganizationConfig.optional(),
});
const updateOrganization_Body = Reason.and(
  z.object({ payload: OrganizationPayload })
);
const SnakeCaseString = z.string();
const UserPermission = z.object({
  permission: SnakeCaseString.regex(/^(?:_*[a-z]+)+$/),
  level: SnakeCaseString.regex(/^(?:_*[a-z]+)+$/),
});
const UserDetails = User.and(
  z.object({ permissions: z.array(UserPermission) })
);
const Users = z.object({
  users: z.array(UserDetails),
  next_cursor: NextCursor.nullable(),
});
const RegisterUserPayload = z.object({
  name: RequiredString.min(1),
  email: z.string().email(),
  employee_id: z.string().optional(),
});
const registerUser_Body = Reason.and(
  z.object({ payload: RegisterUserPayload })
);
const UpdateUserPayload = z.object({
  name: RequiredString.min(1),
  employee_id: z.string().optional(),
  permissions: z.array(UserPermission).optional(),
});
const updateUser_Body = Reason.and(z.object({ payload: UpdateUserPayload }));
const UserPermissions = z.object({ permissions: z.array(UserPermission) });
const updateUserPermissions_Body = Reason.and(
  z.object({ payload: UserPermissions })
);
const AuditLogCategory = z.enum(['security', 'resource', 'signature']);
const AuditLogResourceID = z.string();
const AuditLogEvent = z.object({
  name: z.string(),
  category: AuditLogCategory,
  resource_id: AuditLogResourceID.min(36).max(36).uuid().optional(),
  resource_type: z.string().optional(),
  resource_name: z.string().optional(),
  value_before_update: z.string().optional(),
  value_after_update: z.string().optional(),
});
const AuditLog = z.object({
  id: UUID.min(36).max(36).uuid(),
  user: BasicUserInfo,
  event: AuditLogEvent.optional(),
  added_at: z.string(),
});
const PaginatedAuditLogs = z.object({
  logs: z.array(AuditLog),
  next_cursor: NextCursor.nullable(),
});
const PermissionLevel = z.object({
  name: SnakeCaseString.regex(/^(?:_*[a-z]+)+$/),
  label: z.string(),
});
const Permission = z.object({
  name: SnakeCaseString.regex(/^(?:_*[a-z]+)+$/),
  label: z.string(),
  description: z.string(),
  levels: z.array(PermissionLevel),
});
const Permissions = z.object({ permissions: z.array(Permission) });
const APIToken = z.object({ token: z.string() });
const AblyToken = z.object({ token: z.string() });
const EditTag = z.object({ name: z.string(), color: z.string() });

export const schemas = {
  Versions,
  DeviceVersionChannel,
  DeviceVersion,
  NextCursor,
  PaginatedDeviceVersions,
  UUID,
  OwnerType,
  Owner,
  MetadataWithoutName,
  ResourceReferenceStatus,
  ResourceReference,
  RunHistoryOutcome,
  Tag,
  Tags,
  RunHistory,
  ErrorSeverityEnum,
  RequiredString,
  DeviceErrorInstrument,
  DeviceErrorAction,
  BasicUserInfo,
  DeviceError,
  PauseWarningAffectedTimeConstraint,
  PauseWarningAvailableAction,
  PauseWarningAvailableActions,
  PauseWarning,
  TimeConstraintBrokenPrompt,
  OperatorAction,
  DeviceStateStatus,
  DeviceStateAlertReactionBase,
  DeviceStateAlertReactionMood,
  DeviceStateAlertReactionButton,
  DeviceStateAlertReactionText,
  DeviceStateAlertReaction,
  DeviceStateAlert,
  DeviceState,
  DeviceDataState,
  DeviceDataStates,
  PaginatedDeviceDataStates,
  BatchIDs,
  DeviceDataLog,
  DeviceDataLogs,
  PaginatedDeviceDataLogs,
  DeviceDataOnlyContent,
  RunParameterType,
  RunParameterDefinitionBase,
  RunParameterDefinitionNumber,
  RunParameterValueNumber,
  RunParameterDefinitionString,
  RunParameterValueString,
  RunParameterDefinitionBoolean,
  RunParameterValueBoolean,
  RunParameterDefinitionFile,
  FileReference,
  RunParameterValueFile,
  RunParameterValue,
  RunParameterValues,
  ReferenceDataType,
  ReferenceDataDefinitionBase,
  ReferenceDataDefinitionString,
  ReferenceDataValueString,
  ReferenceDataDefinitionFile,
  ReferenceDataValueFile,
  ReferenceDataDefinitionSampleMap,
  ReferenceDataValueSampleMap,
  ReferenceDataValue,
  ReferenceDataValues,
  DeviceDataOnly,
  RunHistoryDetailed,
  ExportAuditLogs,
  MaestroTaskInstrument,
  MaestroBaseTask,
  MaestroTaskLabware,
  MaestroTransportTask,
  MaestroActionTask,
  MaestroTask,
  MaestroTaskProgressDuration,
  MaestroTaskProgressError,
  MaestroTaskProgress,
  BatchStatus,
  BatchTrace,
  RunPauseAttempt,
  RunPauseInfo,
  MaestroRunProgress,
  HubEventLog,
  HubEventLogs,
  Metadata,
  MetadataWithChecksum,
  WorkflowType,
  WorkflowGitRevisionKind,
  WorkflowGitRevision,
  ResourceVersions,
  WorkflowGit,
  WorkflowMaestro,
  WorkflowDefinition,
  RunInstructionType,
  RunInstruction,
  RunInstructions,
  RunParameterDefinition,
  RunParameterDefinitions,
  ReferenceDataDefinition,
  ReferenceDataDefinitions,
  WorkflowAttributes,
  WorkflowDetailed,
  ReasonDetails,
  Reason,
  RunInstructionPayload,
  RunInstructionsPayload,
  WorkflowPayload,
  updateWorkflow_Body,
  DuplicateResourcePayload,
  Workflow,
  MaestroVersion,
  MaestroVersions,
  EvalVersion,
  EvalVersions,
  ArchetypeVersion,
  ArchetypeVersions,
  deviceIDOrSerial,
  DeviceDeviceState,
  DeviceWorkflow,
  Device,
  DeviceData,
  PolyDetails,
  DeviceDataMetricBase,
  DeviceDataMetricInteger,
  DeviceDataMetricFloat,
  DeviceDataMetricString,
  DeviceDataMetricBoolean,
  DeviceDataMetric,
  DeviceDataMetrics,
  PaginatedDeviceDataMetrics,
  DeviceDataRunHistories,
  PaginatedDeviceRunHistories,
  RenamePayload,
  RunParameterValueNumberPayload,
  RunParameterValueStringPayload,
  RunParameterValueBooleanPayload,
  RunParameterValueFilePayload,
  RunParameterValuePayload,
  RunParameterValuePayloads,
  ReferenceDataValueStringPayload,
  ReferenceDataValueFilePayload,
  ReferenceDataValueSampleMapPayload,
  ReferenceDataValuePayload,
  ReferenceDataValuePayloads,
  LoadPayload,
  loadWorkflowOnDevice_Body,
  deployWorkflowToDevice_Body,
  DeviceConfiguration,
  ConfigurePayload,
  configureDevice_Body,
  StartParameters,
  startDevice_Body,
  StopReasonEnum,
  StopReasonDetails,
  StopReason,
  stopDevice_Body,
  AlertResponseNone,
  AlertResponseText,
  AlertResponse,
  DeviceErrorActionPayload,
  NullableUUID,
  Group,
  FlatGroup,
  MovePayload,
  MoveResult,
  Workflows,
  WorkflowCreatePayload,
  Devices,
  FlatGroups,
  PaginatedFlatGroups,
  ComplianceResourceConfig,
  ComplianceDeviceStateConfig,
  ComplianceConfig,
  OrganizationConfig,
  Organization,
  OrganizationsV2,
  UserStatus,
  User,
  Scope,
  Action,
  RequestIntentionRequest,
  RequestIntentionResponse,
  RequestVerifyRequest,
  RequestVerifyResponse,
  DeviceDataSubscription,
  DeviceDataSubMetric,
  DeviceDataSubLog,
  dummyInternalDoNotUse_Body,
  NotificationChannel,
  NotificationCategory,
  NotificationResource,
  UserNotificationPreferences,
  DeviceNotificationPreferences,
  OrganizationPayload,
  updateOrganization_Body,
  SnakeCaseString,
  UserPermission,
  UserDetails,
  Users,
  RegisterUserPayload,
  registerUser_Body,
  UpdateUserPayload,
  updateUser_Body,
  UserPermissions,
  updateUserPermissions_Body,
  AuditLogCategory,
  AuditLogResourceID,
  AuditLogEvent,
  AuditLog,
  PaginatedAuditLogs,
  PermissionLevel,
  Permission,
  Permissions,
  APIToken,
  AblyToken,
  EditTag,
};

const endpoints = makeApi([
  {
    method: 'get',
    path: '/v1/archetypes/versions',
    requestFormat: 'json',
    response: ArchetypeVersions,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/auth/auth0/exchange',
    requestFormat: 'json',
    parameters: [
      {
        name: 'workspace_id',
        type: 'Query',
        schema: z.string().min(36).max(36).uuid().optional(),
      },
    ],
    response: z.object({ token: z.string() }),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/batches/:batchID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'batchID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: BatchTrace,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/device-versions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'cursor',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'count',
        type: 'Query',
        schema: z.number().int().gte(10).lte(1000).optional(),
      },
      {
        name: 'order_by',
        type: 'Query',
        schema: z.enum(['name', 'added', 'edited']).optional(),
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.enum(['ascending', 'descending']).optional(),
      },
      {
        name: 'filter',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedDeviceVersions,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/devices/:deviceID/deploy',
    requestFormat: 'form-data',
    parameters: [
      {
        name: 'body',
        description: `The workflow to deploy along with any extra data and input files`,
        type: 'Body',
        schema: deployWorkflowToDevice_Body,
      },
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/devices/:deviceID/errors/:deviceErrorID/action',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The action to take on this device error`,
        type: 'Body',
        schema: DeviceErrorActionPayload,
      },
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'deviceErrorID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/devices/:deviceID/history/logs',
    requestFormat: 'json',
    parameters: [
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'range_start',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'range_end',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'cursor',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'count',
        type: 'Query',
        schema: z.number().int().gte(10).lte(1000).optional(),
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.enum(['ascending', 'descending']).optional(),
      },
      {
        name: 'filter',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'run_history_id',
        type: 'Query',
        schema: z.string().min(36).max(36).uuid().optional(),
      },
    ],
    response: PaginatedDeviceDataLogs,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/devices/:deviceID/history/metrics',
    requestFormat: 'json',
    parameters: [
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'range_start',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'range_end',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'cursor',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'count',
        type: 'Query',
        schema: z.number().int().gte(10).lte(1000).optional(),
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.enum(['ascending', 'descending']).optional(),
      },
      {
        name: 'metrics',
        type: 'Query',
        schema: z.array(z.string()).optional(),
      },
      {
        name: 'run_history_id',
        type: 'Query',
        schema: z.string().min(36).max(36).uuid().optional(),
      },
    ],
    response: PaginatedDeviceDataMetrics,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/devices/:deviceID/history/run-histories',
    requestFormat: 'json',
    parameters: [
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'range_start',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'range_end',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'cursor',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'count',
        type: 'Query',
        schema: z.number().int().gte(10).lte(1000).optional(),
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.enum(['ascending', 'descending']).optional(),
      },
      {
        name: 'tags',
        type: 'Query',
        schema: z.array(UUID).optional(),
      },
    ],
    response: PaginatedDeviceRunHistories,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/devices/:deviceID/history/state',
    requestFormat: 'json',
    parameters: [
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'range_start',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'range_end',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'cursor',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'count',
        type: 'Query',
        schema: z.number().int().gte(10).lte(1000).optional(),
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.enum(['ascending', 'descending']).optional(),
      },
      {
        name: 'run_history_id',
        type: 'Query',
        schema: z.string().min(36).max(36).uuid().optional(),
      },
    ],
    response: PaginatedDeviceDataStates,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/devices/:deviceID/rename',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The reference to the Workflow to load`,
        type: 'Body',
        schema: RenamePayload,
      },
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'X-Request-Authorization',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/devices/:deviceID/state/alert-response',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The feedback for this alert`,
        type: 'Body',
        schema: AlertResponse,
      },
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/devices/:deviceID/state/cancel-pause',
    requestFormat: 'json',
    parameters: [
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/devices/:deviceID/state/configure',
    requestFormat: 'json',
    parameters: [
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: DeviceConfiguration,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/devices/:deviceID/state/configure',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Content of the configuration`,
        type: 'Body',
        schema: configureDevice_Body,
      },
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/devices/:deviceID/state/confirm-pause',
    requestFormat: 'json',
    parameters: [
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/devices/:deviceID/state/load',
    requestFormat: 'form-data',
    parameters: [
      {
        name: 'body',
        description: `The reference to the Workflow to load`,
        type: 'Body',
        schema: loadWorkflowOnDevice_Body,
      },
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/devices/:deviceID/state/pause',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The reason why the device operation is being paused`,
        type: 'Body',
        schema: Reason,
      },
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/devices/:deviceID/state/reset',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `User needs to provide a reason to reset a device which is currently in error mode or paused`,
        type: 'Body',
        schema: Reason,
      },
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/devices/:deviceID/state/resume',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `User needs to provide a reason to resume operation (if paused or an action is required)`,
        type: 'Body',
        schema: Reason,
      },
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/devices/:deviceID/state/start',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Parameters for starting protocol`,
        type: 'Body',
        schema: startDevice_Body,
      },
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/devices/:deviceID/state/stop',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The reason why the device operation is being stopped`,
        type: 'Body',
        schema: stopDevice_Body,
      },
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/devices/:deviceID/time-constraints/:timeConstraintBrokenPromptID/ignore',
    requestFormat: 'json',
    parameters: [
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'timeConstraintBrokenPromptID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/devices/:deviceIDOrSerial',
    requestFormat: 'json',
    parameters: [
      {
        name: 'deviceIDOrSerial',
        type: 'Path',
        schema: deviceIDOrSerial,
      },
      {
        name: 'range_start',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'range_end',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'count',
        type: 'Query',
        schema: z.number().int().gte(10).lte(1000).optional(),
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.enum(['ascending', 'descending']).optional(),
      },
    ],
    response: DeviceData,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/evals/versions',
    requestFormat: 'json',
    response: EvalVersions,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/maestro/versions',
    requestFormat: 'json',
    response: MaestroVersions,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organizations',
    requestFormat: 'json',
    response: OrganizationsV2,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/organizations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The definition of the organization`,
        type: 'Body',
        schema: OrganizationPayload,
      },
    ],
    response: Organization,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organizations/:organizationID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: Organization,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/organizations/:organizationID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The definition of the organization`,
        type: 'Body',
        schema: updateOrganization_Body,
      },
      {
        name: 'organizationID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'X-Request-Authorization',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: Organization,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/organizations/:organizationID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `User needs to provide a reason to delete the given organization`,
        type: 'Body',
        schema: Reason,
      },
      {
        name: 'organizationID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'X-Request-Authorization',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organizations/:organizationID/audit-logs',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'resourceID',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'userID',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'cursor',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'count',
        type: 'Query',
        schema: z.number().int().gte(10).lte(1000).optional(),
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.enum(['ascending', 'descending']).optional(),
      },
      {
        name: 'range_start',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'range_end',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'filter',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedAuditLogs,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organizations/:organizationID/audit-logs/export',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'range_start',
        type: 'Query',
        schema: z.string(),
      },
      {
        name: 'range_end',
        type: 'Query',
        schema: z.string(),
      },
    ],
    response: z.object({ download_url: z.string() }),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organizations/:organizationID/users',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'cursor',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'count',
        type: 'Query',
        schema: z.number().int().gte(10).lte(1000).optional(),
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.enum(['ascending', 'descending']).optional(),
      },
    ],
    response: Users,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/organizations/:organizationID/users',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The definition of the user`,
        type: 'Body',
        schema: registerUser_Body,
      },
      {
        name: 'organizationID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: UserDetails,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/organizations/:organizationID/users/:userID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The user details to update`,
        type: 'Body',
        schema: updateUser_Body,
      },
      {
        name: 'organizationID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: UserDetails,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/organizations/:organizationID/users/:userID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `User needs to provide a reason to remove a user`,
        type: 'Body',
        schema: Reason,
      },
      {
        name: 'organizationID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/organizations/:organizationID/users/:userID/permissions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'organizationID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ permissions: z.array(UserPermission) }),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/organizations/:organizationID/users/:userID/permissions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The names of the permissions to set on the user`,
        type: 'Body',
        schema: updateUserPermissions_Body,
      },
      {
        name: 'organizationID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ permissions: z.array(UserPermission) }),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/permissions',
    requestFormat: 'json',
    response: z.object({ permissions: z.array(Permission) }),
    errors: [
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/request/intention',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RequestIntentionRequest,
      },
    ],
    response: z.object({ requestIntention: z.string() }),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/request/verify',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RequestVerifyRequest,
      },
      {
        name: 'X-Id-Token',
        type: 'Header',
        schema: z.string(),
      },
    ],
    response: z.object({ token: z.string() }),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/run-histories/:runHistoryID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'runHistoryID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'range_start',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'range_end',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'count',
        type: 'Query',
        schema: z.number().int().gte(10).lte(1000).optional(),
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.enum(['ascending', 'descending']).optional(),
      },
    ],
    response: RunHistoryDetailed,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/run-histories/:runHistoryID/event-log',
    requestFormat: 'json',
    parameters: [
      {
        name: 'runHistoryID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.array(HubEventLog),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/run-histories/:runHistoryID/logs/export',
    requestFormat: 'json',
    parameters: [
      {
        name: 'runHistoryID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.object({ download_url: z.string() }),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/run-histories/:runHistoryID/progress',
    requestFormat: 'json',
    parameters: [
      {
        name: 'runHistoryID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: MaestroRunProgress,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/run-histories/:runHistoryID/tags/:tagID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'runHistoryID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'tagID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/run-histories/:runHistoryID/tags/:tagID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'runHistoryID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'tagID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/stream',
    description: `This endpoint is provided only to ensure every type for the Socket.IO endpoint are used
so Swagger doesn&#x27;t raise an error and code generators don&#x27;t ignore the unused types.
`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: dummyInternalDoNotUse_Body,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/stream',
    description: `As Swagger doesn&#x27;t support Websocket or Socket.IO, the protocol will be described textually in this section

# Security &amp; Authentication
You must either use JWT or API key authentication to use this endpoint.
This is given to the Socket.IO client as a &#x60;auth&#x60; object, which follows the following schema:
&#x60;&#x60;&#x60;
{
  &quot;mode&quot;: &quot;JWT&quot; | &quot;API&quot;,
  &quot;token&quot;: &lt;either JWT token or API token depending on the mode&gt;,
}
&#x60;&#x60;&#x60;

# Events
Some events will be broadcasted to you even without subscribing to them.

## Versions

*Namespace*: &#x60;versions&#x60;

### Creation

*Event*: &#x60;created&#x60;

*Trigger*: when a new version is created

*Content*: &#x60;$ref: &#x27;#/components/schemas/DeviceVersion&#x27;&#x60;

## Workflow lifecycle

*Namespace*: &#x60;workflows&#x60;

### Creation

*Event*: &#x60;created&#x60;

*Trigger*: when a workflow is created

*Content*: &#x60;$ref: &#x27;#/components/schemas/Workflow&#x27;&#x60;

### Updated

*Event*: &#x60;updated&#x60;

*Trigger*: when a workflow is updated

*Content*: &#x60;$ref: &#x27;#/components/schemas/Workflow&#x27;&#x60;

### Deleted

*Event*: &#x60;deleted&#x60;

*Trigger*: when a workflow is deleted

*Content*: &#x60;$ref: &#x27;#/components/schemas/Workflow&#x27;&#x60;

## Run history lifecycle

*Namespace*: &#x60;run-histories&#x60;

### Creation

*Event*: &#x60;created&#x60;

*Trigger*: when a run-history is created

*Content*: &#x60;$ref: &#x27;#/components/schemas/RunHistory&#x27;&#x60;

### Updated

*Event*: &#x60;updated&#x60;

*Trigger*: when a run-history is updated

*Content*: &#x60;$ref: &#x27;#/components/schemas/RunHistory&#x27;&#x60;

## Device lifecycle

*Namespace*: &#x60;devices&#x60;

### Association

*Event*: &#x60;associated&#x60;

*Trigger*: when a device is associated with the account

*Content*: &#x60;$ref: &#x27;#/components/schemas/Device&#x27;&#x60;

### Status change

*Event*: &#x60;status&#x60;

*Trigger*: when a device status changes

*Content*: &#x60;$ref: &#x27;#/components/schemas/Device&#x27;&#x60;

### Configuration change

*Event*: &#x60;configuration&#x60;

*Trigger*: when a device configuration changes

*Content*: &#x60;$ref: &#x27;#/components/schemas/DeviceConfiguration&#x27;&#x60;

### Groups changed

*Event*: &#x60;groups&#x60;

*Trigger*: when groups changed

*Content*: &#x60;$ref: &#x27;#/components/schemas/Groups&#x27;&#x60;

### (V2) Group Updated
*Event*: &#x60;updated&#x60;

*Trigger*: when groups changed

*Content*: &#x60;$ref: &#x27;#/components/schemas/FlatGroup&#x27;&#x60;


### (V2) Group Created
*Event*: &#x60;created&#x60;

*Trigger*: when group created

*Content*: &#x60;$ref: &#x27;#/components/schemas/FlatGroup&#x27;&#x60;


### (V2) Group Deleted
*Event*: &#x60;deleted&#x60;

*Trigger*: Deleted group

*Content*: &#x60;$ref: &#x27;#/components/schemas/FlatGroup&#x27;&#x60;


### Device data

This event uses a subscription-system to save bandwidth,
if you want to receive this data, you will need to send some of these messages.

### Subscribe

*Event*: &#x60;data/subscribe&#x60;

*Trigger*: client-side, when you want to listen to a particular device

*Content*: &#x60;$ref: &#x27;#/components/schemas/DeviceDataSubscription&#x27;&#x60;

*Response*: &#x60;nil&#x60; (no error) or &#x60;$ref: &#x27;#/components/schemas/Error&#x27;&#x60;

### Unsubscribe

*Event*: &#x60;data/unsubscribe&#x60;

*Trigger*: client-side, when you don&#x27;t want to listen to a particular device anymore

*Content*: &#x60;$ref: &#x27;#/components/schemas/DeviceDataSubscription&#x27;&#x60;

*Response*: &#x60;nil&#x60; (no error) or &#x60;$ref: &#x27;#/components/schemas/Error&#x27;&#x60;

### New device metric

*Event*: &#x60;metrics&#x60;

*Trigger*: when a new metric is received

*Content*: &#x60;$ref: &#x27;#/components/schemas/DeviceDataSubMetric&#x27;&#x60;

### New device log line

*Event*: &#x60;logs&#x60;

*Trigger*: when a new log line is received

*Content*: &#x60;$ref: &#x27;#/components/schemas/DeviceDataSubLog&#x27;&#x60;

### Device error

*Event*: &#x60;device_error&#x60;

*Trigger*: when a device error is received

*Content*: &#x60;$ref: &#x27;#/components/schemas/DeviceError&#x27;&#x60;
`,
    requestFormat: 'json',
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/user',
    requestFormat: 'json',
    response: User,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/users/:userID/notifications/devices/:deviceID/preferences',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The device preference`,
        type: 'Body',
        schema: DeviceNotificationPreferences,
      },
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/users/:userID/notifications/preferences',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The definition of a user&#x27;s notification preference`,
        type: 'Body',
        schema: UserNotificationPreferences,
      },
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: UserNotificationPreferences,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/users/:userID/notifications/preferences',
    requestFormat: 'json',
    parameters: [
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: UserNotificationPreferences,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/users/announce',
    requestFormat: 'json',
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/workflows/:workflowID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'workflowID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: WorkflowDetailed,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/workflows/:workflowID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The definition of the Workflow`,
        type: 'Body',
        schema: updateWorkflow_Body,
      },
      {
        name: 'workflowID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'X-Request-Authorization',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: WorkflowDetailed,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/workflows/:workflowID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `User needs to provide a reason to delete a Workflow.`,
        type: 'Body',
        schema: Reason,
      },
      {
        name: 'workflowID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'X-Request-Authorization',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/workflows/:workflowID/duplicate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The new name for the duplicated workflow`,
        type: 'Body',
        schema: DuplicateResourcePayload,
      },
      {
        name: 'workflowID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'X-Request-Authorization',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: Workflow,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/workflows/:workflowID/run-instructions/:runInstructionType/commit',
    requestFormat: 'json',
    parameters: [
      {
        name: 'workflowID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'runInstructionType',
        type: 'Path',
        schema: z.enum(['pre', 'mid', 'post']),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/workflows/:workflowID/run-instructions/:runInstructionType/skip',
    requestFormat: 'json',
    parameters: [
      {
        name: 'workflowID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'runInstructionType',
        type: 'Path',
        schema: z.enum(['pre', 'mid', 'post']),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/workflows/:workflowID/tags/:tagID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'workflowID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'tagID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/workflows/:workflowID/tags/:tagID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'workflowID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'tagID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/workspace/:workspaceID/devices',
    requestFormat: 'json',
    parameters: [
      {
        name: 'workspaceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'cursor',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'count',
        type: 'Query',
        schema: z.number().int().gte(10).lte(1000).optional(),
      },
      {
        name: 'order_by',
        type: 'Query',
        schema: z.enum(['name', 'added', 'edited']).optional(),
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.enum(['ascending', 'descending']).optional(),
      },
      {
        name: 'filter',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: Devices,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/workspace/:workspaceID/tags',
    requestFormat: 'json',
    parameters: [
      {
        name: 'workspaceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: Tags,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/workspace/:workspaceID/tags',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The definition of the tag`,
        type: 'Body',
        schema: EditTag,
      },
      {
        name: 'workspaceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: Tag,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/workspace/:workspaceID/tags/:tagID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The definition of the tag`,
        type: 'Body',
        schema: EditTag,
      },
      {
        name: 'workspaceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'tagID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: Tag,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/workspace/:workspaceID/tags/:tagID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'workspaceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'tagID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/workspace/:workspaceID/tags/:tagID/archive',
    requestFormat: 'json',
    parameters: [
      {
        name: 'workspaceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'tagID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/workspace/:workspaceID/tags/:tagID/unarchive',
    requestFormat: 'json',
    parameters: [
      {
        name: 'workspaceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'tagID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/workspace/:workspaceID/workflows',
    requestFormat: 'json',
    parameters: [
      {
        name: 'workspaceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'cursor',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'range_start',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'range_end',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'count',
        type: 'Query',
        schema: z.number().int().gte(10).lte(1000).optional(),
      },
      {
        name: 'order_by',
        type: 'Query',
        schema: z.enum(['name', 'added', 'edited']).optional(),
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.enum(['ascending', 'descending']).optional(),
      },
      {
        name: 'filter',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'X-Request-Authorization',
        type: 'Header',
        schema: z.string().optional(),
      },
      {
        name: 'tags',
        type: 'Query',
        schema: z.array(UUID).optional(),
      },
    ],
    response: Workflows,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/workspace/:workspaceID/workflows',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The definition of the Workflow`,
        type: 'Body',
        schema: WorkflowCreatePayload,
      },
      {
        name: 'workspaceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
    ],
    response: Workflow,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/ws/token',
    requestFormat: 'json',
    response: z.object({ token: z.string() }),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v2/device-groups/:groupID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The definition of the group`,
        type: 'Body',
        schema: Group,
      },
      {
        name: 'groupID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'X-Request-Authorization',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: FlatGroup,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v2/device-groups/:groupID',
    requestFormat: 'json',
    parameters: [
      {
        name: 'groupID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'X-Request-Authorization',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v2/devices/:deviceID/groups/move',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The new group for this device`,
        type: 'Body',
        schema: MovePayload,
      },
      {
        name: 'deviceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'X-Request-Authorization',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: MoveResult,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 404,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/user/organizations',
    requestFormat: 'json',
    response: OrganizationsV2,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/workspace/:workspaceID/device-groups',
    requestFormat: 'json',
    parameters: [
      {
        name: 'workspaceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'cursor',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'count',
        type: 'Query',
        schema: z.number().int().gte(10).lte(1000).optional(),
      },
      {
        name: 'order_by',
        type: 'Query',
        schema: z.enum(['name', 'added', 'edited']).optional(),
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.enum(['ascending', 'descending']).optional(),
      },
      {
        name: 'filter',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedFlatGroups,
    errors: [
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v2/workspace/:workspaceID/device-groups',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `The definition of the group`,
        type: 'Body',
        schema: Group,
      },
      {
        name: 'workspaceID',
        type: 'Path',
        schema: z.string().min(36).max(36).uuid(),
      },
      {
        name: 'X-Request-Authorization',
        type: 'Header',
        schema: z.string().optional(),
      },
    ],
    response: FlatGroup,
    errors: [
      {
        status: 400,
        schema: z.void(),
      },
      {
        status: 401,
        schema: z.void(),
      },
      {
        status: 500,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/version',
    requestFormat: 'json',
    response: Versions,
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string) {
  return new Zodios(baseUrl, endpoints);
}
