import { ChangeEvent, useCallback } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';

export type SearchFieldProps = Omit<TextFieldProps, 'onChange'> & {
  onChange: (val: string) => void;
  placeholder: string;
  iconPosition?: 'start' | 'end';
};

export const SearchField = ({
  onChange,
  value,
  placeholder,
  variant = 'standard',
  iconPosition = 'start',
}: SearchFieldProps): JSX.Element => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange]
  );

  return (
    <TextField
      size="small"
      fullWidth
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      // Workaround for type issue https://github.com/mui/material-ui/issues/15697
      variant={variant as any}
      InputProps={{
        startAdornment: iconPosition === 'start' && (
          <InputAdornment position="start">
            <Search fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: iconPosition === 'end' && (
          <InputAdornment position="end">
            <Search fontSize="small" />
          </InputAdornment>
        ),
        // Disable conditionally to prevent console warning
        ...(variant === 'standard' && { disableUnderline: true }),
      }}
    />
  );
};

export default SearchField;
