import {
  IVariant,
  // eslint-disable-next-line no-restricted-imports
  useFlag as useFlagUnleash,
  useVariant as useVariantUnleash,
} from '@unleash/proxy-client-react';

export const activeFlags = [
  'fe.cloud_health_screen',
  'fe.dark_mode',
  'fe.device_error',
  'fe.time_constraints',
  'fe.export_simulate',
  'fe.feedback',
  'fe.hmr.configure',
  'fe.maestro_workflow',
  'fe.reason',
  'fe.workflow.visual-editor.group-nodes',
  'hub.transitions.v1',
  'maintenance_mode',
  'fe.live_run_banner',
  'fe.run_awareness_gantt',
  'fe.run_params',
  'fe.run_params_file_support',
  'fe.informational_maintenance_update_scheduled_for',
  'fe.evals',
  'fe.replan_overlay',
  'fe.tags',
] as const;

export type ActiveFlag = (typeof activeFlags)[number];

export const useFlag = (name: ActiveFlag): boolean | undefined =>
  useFlagUnleash(name);

export const useVariant = (name: ActiveFlag): Partial<IVariant> =>
  useVariantUnleash(name);
