import { Fragment, useEffect, useRef, useState } from 'react';
import { InputLabel, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { v4 as uuidv4 } from 'uuid';
import { Typography } from '../Typography';
import { type DurationChangeHandler, type TimeBreakdown } from './types';
import {
  getTimeBreakdown,
  getTotalSeconds,
  isTimeBreakdown,
  padLeadingZero,
} from './utils';
import { InputBaseStyled, InputsWrap } from './styled';

export type DurationPickerProps = {
  onChange: DurationChangeHandler;
  id?: string;
  label?: string;
  initialValue?: { totalSeconds: number } | TimeBreakdown;
};

export const DurationPicker = ({
  onChange,
  id = `durationPicker-${uuidv4()}`,
  label,
  initialValue = {
    hours: null,
    minutes: null,
    seconds: null,
  },
}: DurationPickerProps): JSX.Element => {
  const inputsWrapRef = useRef<HTMLDivElement>(null);

  const initialValueParsed = isTimeBreakdown(initialValue)
    ? initialValue
    : getTimeBreakdown(initialValue.totalSeconds);
  const {
    seconds: initialSeconds,
    minutes: initialMinutes,
    hours: initialHours,
  } = initialValueParsed;

  const [seconds, setSeconds] = useState<number | null>(initialSeconds);
  const [minutes, setMinutes] = useState<number | null>(initialMinutes);
  const [hours, setHours] = useState<number | null>(initialHours);

  const theme = useTheme();

  type ValueSetter = (value: number | null) => void;
  type Input = {
    id: string;
    value: number | null;
    setValue: ValueSetter;
    placeholder: string;
  };

  const inputs: Input[] = [
    { id: 'hours', value: hours, setValue: setHours, placeholder: 'hh' },
    { id: 'minutes', value: minutes, setValue: setMinutes, placeholder: 'mm' },
    { id: 'seconds', value: seconds, setValue: setSeconds, placeholder: 'ss' },
  ];

  const normalizeInputValues = (): TimeBreakdown => {
    const totalSeconds = getTotalSeconds({ hours, minutes, seconds });
    const {
      hours: newHours,
      minutes: newMinutes,
      seconds: newSeconds,
    } = getTimeBreakdown(totalSeconds);

    setHours(newHours);
    setMinutes(newMinutes);
    setSeconds(newSeconds);

    return { hours: newHours, minutes: newMinutes, seconds: newSeconds };
  };

  const handleInputChange = (value: string, setValue: ValueSetter) => {
    const newValue = value === '' ? null : Number(value);
    if (Number(newValue) > 99) return;
    setValue(newValue);
  };

  const handleAllInputsBlurred = () => {
    if ([seconds, minutes, hours].every((val) => val === null)) return;

    const {
      seconds: newSeconds,
      minutes: newMinutes,
      hours: newHours,
    } = normalizeInputValues(); // vals need to be taken from the return to ensure they update

    if (newSeconds === null) setSeconds(0);
    if (newMinutes === null) setMinutes(0);
    if (newHours === null) setHours(0);
  };

  const handleInputBlur = () => {
    // skip the current event loop to allow the next input to be focused
    setTimeout(() => {
      if (!inputsWrapRef.current?.contains(document.activeElement)) {
        handleAllInputsBlurred();
      }
    });
  };

  useEffect(() => {
    onChange({
      hours,
      minutes,
      seconds,
      get totalSeconds() {
        if (hours === null && minutes === null && seconds === null) return null;
        return getTotalSeconds({ hours, minutes, seconds });
      },
    });
  }, [seconds, minutes, hours, onChange]);

  return (
    <Stack>
      {label && (
        <InputLabel htmlFor={id}>
          <Typography variant="overline" color={theme.palette.text.primary}>
            {label}
          </Typography>
        </InputLabel>
      )}

      <Stack direction="row">
        <Typography variant="body2" component="span">
          <InputsWrap ref={inputsWrapRef}>
            {inputs.map(({ id: inputId, value, setValue, placeholder }, i) => (
              <Fragment key={inputId}>
                <InputBaseStyled
                  value={value === null ? '' : padLeadingZero(value)}
                  onChange={(e) => handleInputChange(e.target.value, setValue)}
                  onBlur={handleInputBlur}
                  placeholder={placeholder}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') setValue(null);
                  }}
                />
                {i < inputs.length - 1 && <span>:</span>}
              </Fragment>
            ))}
          </InputsWrap>
        </Typography>
      </Stack>
    </Stack>
  );
};
